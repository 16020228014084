import { render, staticRenderFns } from "./createVK2_PG_DB_Ads_Exp.vue?vue&type=template&id=2af16d3a"
import script from "./createVK2_PG_DB_Ads_Exp.vue?vue&type=script&lang=js"
export * from "./createVK2_PG_DB_Ads_Exp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports