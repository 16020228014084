<template>
    <v-card class="mx-auto px-6 py-8" max-width="80%" flat>
        <v-card>
            <v-card-title class="bg-amber-lighten-1">
            <h2 class="me-4 font-weight-light">
                Активные пользователи
            </h2>
            </v-card-title>
            <v-card-text>
                Экспортировать Акт. пользователей из Геткурса в Postgresql.
            </v-card-text>
        </v-card>
        <br>
        <v-card flat class="mx-auto px-6 py-1">
            
                <form>
                    <v-text-field
                        v-model="uuid"
                        prepend-icon="mdi-identifier"
                        label="ID Задачи"
                        readonly
                    ></v-text-field>
                    <v-select
                        :items="jobType"
                        prepend-icon="mdi-checkbox-marked-circle-plus-outline"
                        label="Выберите тип задачи"
                        v-model="jobTypeSelect"
                    ></v-select>
                    <v-text-field
                        v-model="jobRepeatEvery"
                        prepend-icon="mdi-timer-alert-outline"
                        :error-messages="nameErrors"
                        label="Запускать через N минут"
                        type="number"
                        required
                        v-show="jobRepeatEveryDisabled"
                        @input="$v.jobRepeatEvery.$touch()"
                        @blur="$v.jobRepeatEvery.$touch()"
                    ></v-text-field>
                    <br>
                    <p v-show="jobScheduledDisabled">Выберите Часы и минуты для регулярного запуска (Например запускать в 4,6,8,9 часов 25 минут)</p>
                    <p v-show="jobScheduledDisabled"><b>Часы для запуска (МСК):</b></p>
                    
                    <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                        <!-- <v-checkbox class="px-3" v-model="ex00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex01" label="01" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox> -->
                        <v-checkbox class="px-3" v-model="ex02" label="02" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex03" label="03" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex04" label="04" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex06" label="06" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex07" label="07" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex08" label="08" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex09" label="09" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex11" label="11" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex12" label="12" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex13" label="13" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex14" label="14" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex16" label="16" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex17" label="17" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex18" label="18" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex19" label="19" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex21" label="21" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex22" label="22" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex23" label="23" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                    </v-card>
                    <br v-show="jobScheduledDisabled">
                    <p v-show="jobScheduledDisabled"><b>Минуты для запуска:</b></p>
                    <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                        <v-checkbox class="px-3" v-model="exm00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm25" label="25" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm30" label="30" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm35" label="35" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm40" label="40" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm45" label="45" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm50" label="50" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3 pb-6" v-model="exm55" label="55" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        
                    </v-card>
                    
                    <v-col cols="12" md="8" lg="6">
                        <v-card class="mb-4">
                            <v-card-title>Группа параметров №1</v-card-title>
                            <v-card-text>
                                <div>Дата последней активности (N дней назад)</div>
                                <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="group1.fromDays"
                                    label="ОТ"
                                    type="number"
                                    @input="updateDates(1)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="group1.toDays"
                                    label="ДО"
                                    type="number"
                                    @input="updateDates(1)"
                                    ></v-text-field>
                                </v-col>
                                </v-row>
                                <div class="mt-2">{{ group1.dateRangeText }}</div>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-4">
                            <v-card-title>Группа параметров №2</v-card-title>
                            <v-card-text>
                                <div>Дата последней активности (N дней назад)</div>
                                <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="group2.fromDays"
                                    label="ОТ"
                                    type="number"
                                    @input="updateDates(2)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="group2.toDays"
                                    label="ДО"
                                    type="number"
                                    @input="updateDates(2)"
                                    ></v-text-field>
                                </v-col>
                                </v-row>
                                <div class="mt-2">{{ group2.dateRangeText }}</div>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-4">
                            <v-card-title>Группа параметров №3</v-card-title>
                            <v-card-text>
                                <div>Дата последней активности (N дней назад)</div>
                                <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="group3.fromDays"
                                    label="ОТ"
                                    type="number"
                                    @input="updateDates(3)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="group3.toDays"
                                    label="ДО"
                                    type="number"
                                    @input="updateDates(3)"
                                    ></v-text-field>
                                </v-col>
                                </v-row>
                                <div class="mt-2">{{ group3.dateRangeText }}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-text-field
                        v-model="schoolURL"
                        prepend-icon="mdi-web"
                        label="URL адрес школы (Например: https://yanavegana.ru)"
                        :error-messages="schoolURLErrors"
                        required
                        @input="handleSchoolURLInput"
                        @blur="$v.schoolURL.$touch()"
                        :readonly = "schoolURLRD"
                    ></v-text-field>
                    <v-card flat v-show="jobScheduledDisabled">
                      <v-card>
                        <v-card-text>
                          <v-form>
                            <v-text-field prepend-icon="mdi-account" name="login" label="E-mail пользователя Геткурс" type="text" v-model="Un"></v-text-field>
                            <v-text-field prepend-icon="mdi-lock" name="password" label="Пароль пользователя Геткурс" id="password" type="password" v-model="Up"></v-text-field>
                            * у пользователя должна быть отключена двухфакторная авторизация, и предоставлены права для доступа к информации в системе Геткурс.<br> 
                            * рекомендуется использовать отдельного пользователя для интеграции (в случае смены пароля, если вы используете свою учетную запись, задача не остановится и продолжит работать)<br>
                            * мы не сохраняем ваши учетные данные, если вы сменили пароль, не забудьте обновить подключение к системе Геткурс в задаче.
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-row>
                            <v-col cols="12" class="d-flex align-center">
                              <v-btn class="ml-2 mb-2" color="primary" @click="CheckGKUserData" :disabled="Ud">Выполнить вход в Геткурс</v-btn>
                              <v-progress-circular
                                class="ml-2 mb-2"
                                indeterminate
                                color="primary"
                                v-show="Ud"
                              ></v-progress-circular>
                            </v-col>
                            <v-col cols="12">
                              <v-alert dense text type="success" v-model="UalertSuccess">
                                {{ UalertMessage }}
                              </v-alert>
                              <v-alert dense text type="info" v-model="UalertWarning">
                                {{ UalertMessage }}
                              </v-alert>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    <v-text-field
                        v-model="schoolApiKey"
                        prepend-icon="mdi-cookie-outline"
                        label="Cookie ключ для подключения"
                        :error-messages="schoolApiKeyErrors"
                        required
                        @input="$v.schoolApiKey.$touch()"
                        @blur="$v.schoolApiKey.$touch()"
                        v-show="false"
                    ></v-text-field>
                    <v-text-field
                        class="mt-4"
                        v-model="dbTableName"
                        prepend-icon="mdi-database-check-outline"
                        label="Название таблицы в БД PostgreSQL"
                        :error-messages="dbTableNameErrors"
                        required
                        @input="$v.dbTableName.$touch()"
                        @blur="$v.dbTableName.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="strJobUserComment"
                        prepend-icon="mdi-comment-edit-outline"
                        label="Комментарий пользователя"
                        :error-messages="strJobUserCommentErrors"
                        required
                        @input="$v.strJobUserComment.$touch()"
                        @blur="$v.strJobUserComment.$touch()"
                    ></v-text-field>
                    </v-card>
                    
                </form>
                <v-card
                    class="d-flex justify-space-between mb-6"
                    flat
                    tile
                    >
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showStartButton"
                        >
                            <v-btn
                                class="primary"
                                @click="submit"
                                >
                                запустить задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showSaveCopyButtons"
                        >
                            <v-btn
                                class="success"
                                @click="submit"
                                >
                                сохранить задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showSaveCopyButtons"
                        >
                            <v-btn
                                class="warning"
                                @click="CopyJobtoNew"
                                >
                                скопировать задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                        >
                            <v-btn
                                class="primary"
                                @click="CancelOper"
                                >
                                Отмена
                            </v-btn>
                        </v-card>
                    </v-card>
        </v-card>
        <br>
        <div>
            <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                        Задача запущена!
            </v-alert>
            <v-alert dense  text   type="error"  dismissible v-model="alert2" class="pa-1 ma-1">
                        {{ alertMessage }}
            </v-alert>
        </div>
        
    </v-card>
    
    
  </template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer'; 
import * as sha512 from 'js-sha512'

//import { DxButton } from 'devextreme-vue/button';

import axios from 'axios'
import store from '../store/store.js'



export default {
    mixins: [validationMixin],

    validations: {
        jobRepeatEvery: { required },
        waitTime: { required },
        schoolURL: { required },
        schoolApiKey: { required },
        dbTableName: { required },
        strJobUserComment: { required },
    },

    data: () => ({
        Un: '',
        Up: '',
        Ud: false,
        UalertSuccess: false,
        UalertWarning: false,
        UalertMessage: '',
        showGenerateQueryDialog: false,
        showSaveCopyButtons: false,
        showStartButton: true,
        group1: { fromDays: 0, toDays: 7, dateRangeText: '' },
        group2: { fromDays: 0, toDays: 30, dateRangeText: '' },
        group3: { fromDays: 0, toDays: 90, dateRangeText: '' },
        show: false,
        uuid: uuidv4(),
        schoolURLRD: false,
        jobRepeatEvery: '',
        jobRepeatEveryDisabled: false,
        jobScheduled: '',
        jobScheduledDisabled: false,
        //jobType: ['Разовая задача', 'Регулярная задача'],
        jobType: ['Регулярная задача'],
        jobTypeSelect: '',
        alert1: false,
        alert2: false,
        alert3: false,
        alert4: false,
        alert5: false,
        alert6: false,
        alertMessage: '',
        canRunJobCheck: false,
        ex00: 0,
        ex01: 0,
        ex02: 0,
        ex03: 0,
        ex04: 1,
        ex05: 0,
        ex06: 1,
        ex07: 0,
        ex08: 1,
        ex09: 1,
        ex10: 0,
        ex11: 0,
        ex12: 0,
        ex13: 0,
        ex14: 0,
        ex15: 0,
        ex16: 0,
        ex17: 0,
        ex18: 0,
        ex19: 0,
        ex20: 0,
        ex21: 0,
        ex22: 0,
        ex23: 0,
        exm00: 0,
        exm05: 0,
        exm10: 0,
        exm15: 0,
        exm20: 0,
        exm25: 1,
        exm30: 0,
        exm35: 0,
        exm40: 0,
        exm45: 0,
        exm50: 0,
        exm55: 0,
        sheetId: '',
        spreadsheetId: '',
        rangeAdd: '0',
        dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modal: false,
        waitTime: '1',
        schoolURL: '',
        schoolApiKey: '',
        ExportID: '',
        ShowExportID: false,
        selectedColumns:  [],
        fromGcColumns: [],
        showTimer: false,
        timerEnabled: true,
        timerCount: 100,
        getGKColumnsExpDisabled: false,
        dbTableName: '',
        ClearScheet: false,
        pgcolumns: [
            { ID: 0, columnName: 'dtDate',  columnType: 3, columnLeng: 0, columnGKName: 1, altertbl: 0 },
            { ID: 1, columnName: 'p1',  columnType: 1, columnLeng: 0, columnGKName: 2, altertbl: 0 },
            { ID: 2, columnName: 'p2',  columnType: 1, columnLeng: 0, columnGKName: 3, altertbl: 0 },
            { ID: 3, columnName: 'p3',  columnType: 1, columnLeng: 0, columnGKName: 4, altertbl: 0 }
        ],
        gkcolumns: [
            { ID: 0, Name: 'NULL' },
            { ID: 1, Name: 'dtDate' },
            { ID: 2, Name: 'p1' },
            { ID: 3, Name: 'p2' },
            { ID: 4, Name: 'p3' }
        ],
        columntypes: [
            { ID: 0, Name: 'Строка' },
            { ID: 1, Name: 'Целое число' },
            { ID: 2, Name: 'Сумма' },
            { ID: 3, Name: 'Дата' },
            { ID: 4, Name: 'Время' },
            { ID: 5, Name: 'Текст' }
        ],
        selectedItemKeys: [],
        strJobUserComment: ''
    }),

    //{ ID: 14, Name: 'banners' },
    //{ ID: 15, Name: 'buttons' },
    //{ ID: 15, Name: 'region' },

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.jobRepeatEvery.$dirty) return errors
            !this.$v.jobRepeatEvery.required && errors.push('Обязательное поле. Укажите количество минут через которое произведем запуск разовой задачи.')
        return errors
      },
      schoolURLErrors () {
        const errors = []
        if (!this.$v.schoolURL.$dirty) return errors
            !this.$v.schoolURL.required && errors.push('Обязательное поле. URL адрес Геткурса для загрузки данных. (https://yanavegana.ru)')
        return errors
      },
      schoolApiKeyErrors () {
        const errors = []
        if (!this.$v.schoolApiKey.$dirty) return errors
            !this.$v.schoolApiKey.required && errors.push('Обязательное поле. Учетные данные для подключения и закрузки данных из Геткурса.')
        return errors
      },
      dbTableNameErrors () {
        const errors = []
        if (!this.$v.dbTableName.$dirty) return errors
            !this.$v.dbTableName.required && errors.push('Обязательное поле. Название таблицы для отнрузки данных в PostgreSQL.')
        return errors
      },
        strJobUserCommentErrors () {
            const errors = []
            if (!this.$v.strJobUserComment.$dirty) return errors
                !this.$v.strJobUserComment.required && errors.push('Обязательное поле. Комментарий пользовтеля')
            return errors
        },
    },

    async mounted() {
        this.updateDates(1)
        this.updateDates(2)
        this.updateDates(3)

        this.uuid = uuidv4();

        if(this.$store.getters.ProjectCOPY == 1 || this.$store.getters.ProjectEDIT == 1 || this.$store.getters.ProjectCOPY == 2){
            if(this.$store.getters.ProjectNAME == 'PG Активные пользователи из Геткурса в Базу Данных')
            {
                const settingJobUUID = {
                    strProjectUUID: this.$store.getters.ProjectUUID,
                }
                await this.$store.dispatch('loadjobProjectsById', settingJobUUID)
                .then(async() => {
                    this.ex00= 0;
                    this.ex01= 0;
                    this.ex02= 0;
                    this.ex03= 0;
                    this.ex04= 0;
                    this.ex05= 0;
                    this.ex06= 0;
                    this.ex07= 0;
                    this.ex08= 0;
                    this.ex09= 0;
                    this.ex10= 0;
                    this.ex11= 0;
                    this.ex12= 0;
                    this.ex13= 0;
                    this.ex14= 0;
                    this.ex15= 0;
                    this.ex16= 0;
                    this.ex17= 0;
                    this.ex18= 0;
                    this.ex19= 0;
                    this.ex20= 0;
                    this.ex21= 0;
                    this.ex22= 0;
                    this.ex23= 0;
                    this.exm00= 0;
                    this.exm05= 0;
                    this.exm10= 0;
                    this.exm15= 0;
                    this.exm20= 0;
                    this.exm25= 0;
                    this.exm30= 0;
                    this.exm35= 0;
                    this.exm40= 0;
                    this.exm45= 0;
                    this.exm50= 0;
                    this.exm55= 0;

                    //console.log('Chto to1')
                    //console.log(this.$store.getters.jobProjectsById[0])
                    this.uuid = this.$store.getters.jobProjectsById[0].UUID
                    this.dbTableName = this.$store.getters.jobProjectsById[0].spreadsheetId.replace('tbl_lastactiveusr_','')
                    this.rangeAdd = this.$store.getters.jobProjectsById[0].rangeAdd
                    

                    this.waitTime = this.$store.getters.jobProjectsById[0].waitTime.replace(/\D/g,'');
                    this.schoolURL = this.$store.getters.jobProjectsById[0].schoolURL
                    this.schoolApiKey = this.$store.getters.jobProjectsById[0].schoolApiKey

                    this.ClearScheet = this.$store.getters.jobProjectsById[0].clearScheet

                    //this.strJobUserComment = this.$store.getters.jobProjectsById[0].strJobUserComment
                    this.strJobUserComment = this.$store.getters.jobProjectsById[0].strJobUserComment == null ? '' : this.$store.getters.jobProjectsById[0].strJobUserComment.toString().trim()

                    this.jobType = ['Разовая задача', 'Регулярная задача']

                    if(this.$store.getters.jobProjectsById[0].strJobRepeat == 'repeatEvery'){
                        this.jobTypeSelect = 'Регулярная задача';
                    }
                    else
                    {
                        this.jobTypeSelect = 'Разовая задача';
                    }


                    let CronTime = this.$store.getters.jobProjectsById[0].strJobTime;
                    let CronTimeArr = CronTime.split(' ');

                    //console.log(CronTimeArr[1]);

                    let CronTimeMinute = CronTimeArr[0];

                    if(CronTimeMinute == 0){ this.exm00 = 1}
                    if(CronTimeMinute == 5){ this.exm05 = 1}
                    if(CronTimeMinute == 10){ this.exm10 = 1}
                    if(CronTimeMinute == 15){ this.exm15 = 1}
                    if(CronTimeMinute == 20){ this.exm20 = 1}
                    if(CronTimeMinute == 25){ this.exm25 = 1}
                    if(CronTimeMinute == 30){ this.exm30 = 1}
                    if(CronTimeMinute == 35){ this.exm35 = 1}
                    if(CronTimeMinute == 40){ this.exm40 = 1}
                    if(CronTimeMinute == 45){ this.exm45 = 1}
                    if(CronTimeMinute == 50){ this.exm50 = 1}
                    if(CronTimeMinute == 55){ this.exm55 = 1}

                    let CronTimeHoursArr = CronTimeArr[1].split(',');

                    for(let iCron = 0; iCron<CronTimeHoursArr.length; iCron++){
                        if(CronTimeHoursArr[iCron] == 0){ this.ex00 = 1}
                        if(CronTimeHoursArr[iCron] == 1){ this.ex01 = 1}
                        if(CronTimeHoursArr[iCron] == 2){ this.ex02 = 1}
                        if(CronTimeHoursArr[iCron] == 3){ this.ex03 = 1}
                        if(CronTimeHoursArr[iCron] == 4){ this.ex04 = 1}
                        if(CronTimeHoursArr[iCron] == 5){ this.ex05 = 1}
                        if(CronTimeHoursArr[iCron] == 6){ this.ex06 = 1}
                        if(CronTimeHoursArr[iCron] == 7){ this.ex07 = 1}
                        if(CronTimeHoursArr[iCron] == 8){ this.ex08 = 1}
                        if(CronTimeHoursArr[iCron] == 9){ this.ex09 = 1}
                        if(CronTimeHoursArr[iCron] == 10){ this.ex10 = 1}
                        if(CronTimeHoursArr[iCron] == 11){ this.ex11 = 1}
                        if(CronTimeHoursArr[iCron] == 12){ this.ex12 = 1}
                        if(CronTimeHoursArr[iCron] == 13){ this.ex13 = 1}
                        if(CronTimeHoursArr[iCron] == 14){ this.ex14 = 1}
                        if(CronTimeHoursArr[iCron] == 15){ this.ex15 = 1}
                        if(CronTimeHoursArr[iCron] == 16){ this.ex16 = 1}
                        if(CronTimeHoursArr[iCron] == 17){ this.ex17 = 1}
                        if(CronTimeHoursArr[iCron] == 18){ this.ex18 = 1}
                        if(CronTimeHoursArr[iCron] == 19){ this.ex19 = 1}
                        if(CronTimeHoursArr[iCron] == 20){ this.ex20 = 1}
                        if(CronTimeHoursArr[iCron] == 21){ this.ex21 = 1}
                        if(CronTimeHoursArr[iCron] == 22){ this.ex22 = 1}
                        if(CronTimeHoursArr[iCron] == 23){ this.ex23 = 1}
                    }

                    let GetJsonFromReq = JSON.parse(this.$store.getters.jobProjectsById[0].schoolColumns)
                    this.pgcolumns = GetJsonFromReq[1]
                    this.gkcolumns = GetJsonFromReq[2]
                    this.columntypes = GetJsonFromReq[3]

                    GetJsonFromReq = [];
                    let ParamGet =  JSON.parse(this.$store.getters.jobProjectsById[0].schoolColumnsOut)
                    this.group1 = ParamGet[0]
                    this.group2 = ParamGet[1]
                    this.group3 = ParamGet[2]

                    
                    if(this.$store.getters.ProjectCOPY == 1){
                        //console.log(1)
                        this.showSaveCopyButtons = false;
                        this.showStartButton = true;
                        this.uuid = uuidv4();

                        if(this.schoolURL !== ''){
                          this.Ud = false;
                          this.UalertWarning = false;
                          this.UalertSuccess = true;
                          this.UalertMessage = 'Проверка подключения к платформе Геткурс - для ' + this.schoolURL +', выполнена!';
                        }
                    }

                    if(this.$store.getters.ProjectCOPY == 2){
                        //console.log(1)
                        this.jobTypeSelect = 'Разовая задача';
                        this.schoolURLRD = true;
                        this.showSaveCopyButtons = false;
                        this.showStartButton = true;
                        this.uuid = uuidv4();
                    }

                    if(this.$store.getters.ProjectEDIT == 1){
                        //console.log(1)
                        this.showSaveCopyButtons = true;
                        this.showStartButton = false;
                        //this.uuid = uuidv4();

                        if(this.schoolURL !== ''){
                          this.Ud = false;
                          this.UalertWarning = false;
                          this.UalertSuccess = true;
                          this.UalertMessage = 'Проверка подключения к платформе Геткурс - для ' + this.schoolURL +', выполнена!';
                        }
                    }
                })
            }
        }
    },

    methods: {
        generateKey(date = new Date()) {
            const months = ['ЯНВАРЯ', 'ФЕВРАЛЯ', 'МАРТА', 'АПРЕЛЯ', 'МАЯ', 'ИЮНЯ', 
                    'ИЮЛЯ', 'АВГУСТА', 'СЕНТЯБРЯ', 'ОКТЯБРЯ', 'НОЯБРЯ', 'ДЕКАБРЯ'];
            const days = ['ВОСКРЕСЕНЬЕ', 'ПОНЕДЕЛЬНИК', 'ВТОРНИК', 'СРЕДА', 'ЧЕТВЕРГ', 'ПЯТНИЦА', 'СУББОТА'];
            
            // Преобразуем входные данные в UTC Date объект
            let utcDate;
            if (typeof date === 'string') {
                utcDate = new Date(date);
            } else if (date instanceof Date) {
                utcDate = new Date(date.getTime());
            } else {
                utcDate = new Date();
            }
            
            // Устанавливаем время в UTC
            utcDate.setTime(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
            
            const day = String(utcDate.getUTCDate()).padStart(2, '0');
            const month = months[utcDate.getUTCMonth()];
            const year = utcDate.getUTCFullYear();
            const weekday = days[utcDate.getUTCDay()];
            
            return `${day}-${month}-${year}-${weekday}`;
        },
        encrypt(message, date = new Date()) {
            const key = this.generateKey(date);
            let encryptedMessage = '';
            let keyHash = sha512(key);
            
            for (let i = 0; i < message.length; i++) {
                let charCode = message.charCodeAt(i) ^ keyHash.charCodeAt(i % keyHash.length);
                encryptedMessage += String.fromCharCode(charCode);
            }
            
            return Buffer.from(encryptedMessage).toString('base64');
        },
        decrypt(encryptedMessage, date = new Date()) {
            const key = this.generateKey(date);
            let message = Buffer.from(encryptedMessage, 'base64').toString();
            let decryptedMessage = '';
            let keyHash = sha512(key);
            
            for (let i = 0; i < message.length; i++) {
                let charCode = message.charCodeAt(i) ^ keyHash.charCodeAt(i % keyHash.length);
                decryptedMessage += String.fromCharCode(charCode);
            }
            
            return decryptedMessage;
        },
        CheckGKUserData()
        {
            if(this.schoolURL.trim() == '') { 
                this.UalertWarning = true;
                this.UalertSuccess = false;
                this.UalertMessage = 'Обязательное поле. URL адрес Геткурса для загрузки данных.';
                return;
            }
            if(this.Un.trim() !== '' && this.Up.trim() !== '') {
                this.schoolApiKey = '';
                this.Ud = true;
                this.UalertWarning = true;
                this.UalertSuccess = false;
                this.UalertMessage = 'Проверяем данные на платформе Геткурс. Подождите...';

                let UserData = 
                { Un: this.Un, Up: this.Up }

                let originalMessage = JSON.stringify(UserData);
                let currentDate = new Date(); // Используем текущую дату

                let SessionData = this.encrypt(originalMessage, currentDate);

                if(this.schoolURL.trim().substring(this.schoolURL.length-1) == '/')
                {
                    this.schoolURL = this.schoolURL.trim().substring(0,this.schoolURL.length-1);
                }

                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'schoolURL': this.schoolURL.trim(),
                        'SessionData': SessionData.trim(),
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'checkUserGetcourseLogin',
                    data: []

                })
                .then((response) => {
                    
                    if(response.data !== false && response.data !=='Error')
                    {
                    console.log(response.data)
                    let DomainGet = this.schoolURL.replace('https://', '.').replace('http://', '.').replace('/', '');
                    //console.log(response.data.value)
                    this.schoolApiKey = response.data.value
                    if(response.data.domain == DomainGet){
                        this.Ud = false;
                        this.UalertWarning = false;
                        this.UalertSuccess = true;
                        this.UalertMessage = 'Проверка подключения к платформе Геткурс - выполнена!';
                    }
                    else
                    {
                        this.Ud = false;
                        this.UalertWarning = false;
                        this.UalertSuccess = true;
                        this.UalertMessage = 'Проверка подключения к платформе Геткурс - выполнена! Обратите внимание что в школе используется другой домен - ' + response.data.domain + '. Замените URL школы на корректный домен!';
                    }
                    }
                    else
                    {
                    this.Ud = false;
                    this.UalertWarning = true;
                    this.UalertSuccess = false;
                    this.UalertMessage = 'Не удалось проверить подключение к Геткурсу.';
                    }
                })
                .catch((response) => {
                    //console.log(response)
                    let err = Object.assign({}, response)
                    console.log('error', err.response);

                    this.Ud = false;
                    this.UalertWarning = true;
                    this.UalertSuccess = false;
                    this.UalertMessage = 'Не удалось проверить подключение к Геткурсу.';
                                    
                    if(err.response.status == '488')
                    {
                        this.$router.push('/')
                    }
                });
            }
            else
            {
                this.UalertWarning = true;
                this.UalertSuccess = false;
                this.UalertMessage = 'Для проверки данных геткурса нужно ввести учетные данные.';
            }
        }, 
        extractDomainName(url) {
            try {
                const parsedUrl = new URL(url);
                const hostnameParts = parsedUrl.hostname.split('.');

                if (hostnameParts.includes('getcourse')) {
                    return hostnameParts[0];
                }
                
                // Для случаев вроде 'course.polinabolshakova.ru'
                if (hostnameParts.length > 2 && hostnameParts[0] !== 'www') {
                    return hostnameParts[hostnameParts.length - 2].replace(/[-_]/g, '');
                }
                
                // Для случаев вроде 'yanavegana.ru'
                return hostnameParts[hostnameParts.length - 2].replace(/[-_]/g, '');
                
            } catch (error) {
                // Если URL некорректный, возвращаем пустую строку
                return '';
            }
        },
        handleSchoolURLInput(value) {
            this.$v.schoolURL.$touch();
            this.dbTableName = this.extractDomainName(value);
        },
        CopyJobtoNew (){
            this.showSaveCopyButtons = false;
            this.showStartButton = true;
            this.uuid = uuidv4();
            this.jobTypeSelect = 'Регулярная задача';
        },
        CancelOper () {
            this.$router.push('/mainScreen/cronJobList')
            this.$emit('close');
        },
        updateDates(groupNumber) {
            const group = this[`group${groupNumber}`]
            const today = new Date()
            const fromDate = new Date(today.getTime() - group.fromDays * 24 * 60 * 60 * 1000)
            const toDate = new Date(today.getTime() - group.toDays * 24 * 60 * 60 * 1000)
            
            group.dateRangeText = `c ${this.formatDate(toDate)} по ${this.formatDate(fromDate)}`
        },
        formatDate(date) {
            const options = { day: 'numeric', month: 'long' }
            return date.toLocaleDateString('ru-RU', options)
        },
        selectionChanged (data) {
            this.selectedItemKeys = data.selectedRowKeys;
            //console.log(this.selectedItemKeys)
        },
        addRecords () {
            //console.log(this.pgcolumns.length);
            this.pgcolumns.push({ ID: this.pgcolumns.length, columnName: '',  columnType: 0, columnLeng: 50, columnGKName: 0, altertbl: 0 })
            //console.log(this.pgcolumns);
        },
        submit () {
            this.canRunJobCheck = true;
            let ErrorMessage = '';

            if(this.ex00 == 0 && 
                this.ex02 == 0 && 
                this.ex03 == 0 && 
                this.ex04 == 0 && 
                this.ex05 == 0 && 
                this.ex06 == 0 && 
                this.ex07 == 0 && 
                this.ex08 == 0 && 
                this.ex09 == 0 && 
                this.ex10 == 0 && 
                this.ex11 == 0 && 
                this.ex12 == 0 && 
                this.ex13 == 0 && 
                this.ex14 == 0 && 
                this.ex15 == 0 && 
                this.ex16 == 0 && 
                this.ex17 == 0 && 
                this.ex18 == 0 && 
                this.ex19 == 0 && 
                this.ex20 == 0 && 
                this.ex21 == 0 && 
                this.ex22 == 0 && 
                this.ex23 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны часы для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.exm00 == 0 && 
                this.exm05 == 0 && 
                this.exm10 == 0 && 
                this.exm15 == 0 && 
                this.exm20 == 0 && 
                this.exm25 == 0 && 
                this.exm30 == 0 && 
                this.exm35 == 0 && 
                this.exm40 == 0 && 
                this.exm45 == 0 && 
                this.exm50 == 0 && 
                this.exm55 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны минуты для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.strJobUserComment == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Комментарий для задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.dbTableName == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введено Название таблицы; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            const validColumnNameRegex = /^[a-zA-Z0-9_]+$/;
            if (!validColumnNameRegex.test(this.dbTableName)) {
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Название таблицы может содержать только латинские буквы, цифры и знак подчеркивания; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.schoolApiKey == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Учетные данные для подключения к Геткурсу требуют проверки; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }
            
            if(this.jobTypeSelect == 'Регулярная задача'){
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из Геткурса, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            else
            {
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из Геткурса, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery == ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введен параметр Запускать через N минут; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Параметр Запускать через N минут, не может быть меньше или равен 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            //console.log(this.jobRepeatEvery);
            if(this.canRunJobCheck == true){
                if(this.jobTypeSelect != '')
                {
                    let TmpColumnData = [];
                    for(let i=0;i<this.pgcolumns.length;i++){
                        //console.log(this.columntypes[this.pgcolumns[i].columnType].Name);
                        TmpColumnData.push({ ID: i, columnName: this.pgcolumns[i].columnName,  columnType: this.columntypes[this.pgcolumns[i].columnType].Name, columnLeng: this.pgcolumns[i].columnLeng, columnGKName: this.gkcolumns[this.pgcolumns[i].columnGKName].Name, altertbl: this.pgcolumns[i].altertbl })
                    }

                    let NewColumnData = TmpColumnData;
                    TmpColumnData = [];

                    let toDBColumn = [];

                    /* toDBColumn['PGWork'] = [];
                    toDBColumn['PGcolumns'] = [];
                    toDBColumn['GKcolumns'] = [];
                    toDBColumn['TYPEcolumns'] = []; */

                    toDBColumn.push(NewColumnData);
                    toDBColumn.push(this.pgcolumns);
                    toDBColumn.push(this.gkcolumns);
                    toDBColumn.push(this.columntypes);


                    //console.log(toDBColumn);
                    //console.log(JSON.stringify(toDBColumn))

                    //let data = toDBColumn
                    //console.log(data)

                    let Hours = '';
                    let Minutes = '';

                    if(this.ex00 == 1){ Hours = Hours + '0,' }
                    if(this.ex01 == 1){ Hours = Hours + '1,' }
                    if(this.ex02 == 1){ Hours = Hours + '2,' }
                    if(this.ex03 == 1){ Hours = Hours + '3,' }
                    if(this.ex04 == 1){ Hours = Hours + '4,' }
                    if(this.ex05 == 1){ Hours = Hours + '5,' }
                    if(this.ex06 == 1){ Hours = Hours + '6,' }
                    if(this.ex07 == 1){ Hours = Hours + '7,' }
                    if(this.ex08 == 1){ Hours = Hours + '8,' }
                    if(this.ex09 == 1){ Hours = Hours + '9,' }
                    if(this.ex10 == 1){ Hours = Hours + '10,' }
                    if(this.ex11 == 1){ Hours = Hours + '11,' }
                    if(this.ex12 == 1){ Hours = Hours + '12,' }
                    if(this.ex13 == 1){ Hours = Hours + '13,' }
                    if(this.ex14 == 1){ Hours = Hours + '14,' }
                    if(this.ex15 == 1){ Hours = Hours + '15,' }
                    if(this.ex16 == 1){ Hours = Hours + '16,' }
                    if(this.ex17 == 1){ Hours = Hours + '17,' }
                    if(this.ex18 == 1){ Hours = Hours + '18,' }
                    if(this.ex19 == 1){ Hours = Hours + '19,' }
                    if(this.ex20 == 1){ Hours = Hours + '20,' }
                    if(this.ex21 == 1){ Hours = Hours + '21,' }
                    if(this.ex22 == 1){ Hours = Hours + '22,' }
                    if(this.ex23 == 1){ Hours = Hours + '23,' }

                    Hours = Hours.substring(0,Hours.length-1);
                    //console.log(Hours)

                    if(this.exm00 == 1){ Minutes = '0' }
                    if(this.exm05 == 1){ Minutes = '5' }
                    if(this.exm10 == 1){ Minutes = '10' }
                    if(this.exm15 == 1){ Minutes = '15' }
                    if(this.exm20 == 1){ Minutes = '20' }
                    if(this.exm25 == 1){ Minutes = '25' }
                    if(this.exm30 == 1){ Minutes = '30' }
                    if(this.exm35 == 1){ Minutes = '35' }
                    if(this.exm40 == 1){ Minutes = '40' }
                    if(this.exm45 == 1){ Minutes = '45' }
                    if(this.exm50 == 1){ Minutes = '50' }
                    if(this.exm55 == 1){ Minutes = '55' }

                    //console.log(Minutes + ' ' + Hours + ' * * *')
                    //schedule, in 20 minutes
                    //repeatEvery, 3 minutes
                    let CronType = '';
                    let CronTime = '';
                    let CronWaitTime = 'in ' + this.waitTime + ' minutes';

                    if(this.schoolURL.trim().substring(this.schoolURL.length-1) == '/')
                    {
                        this.schoolURL = this.schoolURL.trim().substring(0,this.schoolURL.length-1);
                    }

                    if(this.jobTypeSelect == 'Регулярная задача'){
                        CronType = 'repeatEvery';
                        CronTime = Minutes + ' ' + Hours + ' * * *';
                    }
                    else {
                        CronType = 'schedule';
                        CronTime = 'in ' + this.jobRepeatEvery + ' minutes';
                    }

                    let GroupALL = []
                    GroupALL.push(this.group1)
                    GroupALL.push(this.group2)
                    GroupALL.push(this.group3)

                    //console.log(GroupALL[0].fromDays)
                    //console.log(GroupALL[0].toDays)

                    let setJobURLonButton = '';
                    if(this.showSaveCopyButtons == true){ setJobURLonButton = 'updateJobinDB' }
                    if(this.showStartButton == true){ setJobURLonButton = 'addJob' }

                    //console.log(setJobURLonButton)
                    
                    axios({
                        method: 'POST',
                        headers: {
                            'api_key': store.state.CurrentApiKey,
                            'jobUUID': this.uuid,
                            'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                            'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                            'schoolColumns': Buffer.from(JSON.stringify(''), 'utf-8').toString('base64'),
                            'dbTableNameSql': 'tbl_lastactiveusr_' + this.dbTableName,
                            'Content-Type': 'application/json'
                        },
                        url: store.state.urlCJ + 'pgCreateNewTableForTask',
                        data: toDBColumn

                    })
                    .then((response) => {
                        console.log(response.data)
                        if(response.data == 'OK')
                        {
                            console.log(response.data);

                            axios({
                                method: 'POST',
                                headers: {
                                    'api_key': store.state.CurrentApiKey,
                                    'jobUUID': this.uuid,
                                    'jobRepeatEvery': CronTime,
                                    'jobType': CronType,
                                    'jobName': 'GC to PG Active User Working',
                                    'sheetId': this.sheetId,
                                    'spreadsheetId': 'tbl_lastactiveusr_' + this.dbTableName,
                                    'rangeAdd': this.rangeAdd,
                                    'dateStart': this.dateStart,
                                    'waitTime': CronWaitTime,
                                    'schoolURL': this.schoolURL,
                                    'schoolApiKey': this.schoolApiKey,
                                    'strJobNameAbout': Buffer.from('PG Активные пользователи из Геткурса в Базу Данных', 'utf-8').toString('base64'),
                                    'schoolColumns': Buffer.from(JSON.stringify(''), 'utf-8').toString('base64'),
                                    'schoolColumnsOut': Buffer.from(JSON.stringify(GroupALL), 'utf-8').toString('base64'),
                                    'clearScheet': this.ClearScheet,
                                    'useFilterRules': this.selectParams,
                                    'filterRules': Buffer.from('', 'utf-8').toString('base64'),
                                    'strJobUserComment': Buffer.from(this.strJobUserComment, 'utf-8').toString('base64'),
                                    'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                                    'intLitePro': 1,
                                    'Content-Type': 'application/json'
                                },
                                url: store.state.urlCJ + setJobURLonButton,
                                data: toDBColumn
                            })
                            .then((response) => {
                                //console.log(response.data)
                                if(response.data == 'OK')
                                {
                                    this.alert1 = true;
                                    this.alert2 = false;
                                    this.alert3 = false;
                                    this.alert4 = false;
                                    this.alert5 = false;
                                    this.alert6 = false;
                                    if(this.$store.getters.ProjectCOPY == 1 || this.$store.getters.ProjectEDIT == 1){
                                        this.$router.push('/mainScreen/cronJobList')
                                        this.$emit('close');
                                    }
                                }
                            })
                            .catch((response) => {
                                //console.log(response)
                                let err = Object.assign({}, response)
                                console.log('error', err.response);
                                                
                                if(err.response.status == '488')
                                {
                                    this.$router.push('/')
                                }
                            });
                        }
                        else
                        {
                            if(response.data == 'Found System Job from Running list')
                            {
                                this.alert1 = false;
                                this.alert2 = true;
                                this.alert3 = false;
                                this.alert4 = false;
                                this.alert5 = false;
                                this.alert6 = false;
                            }
                            else
                            {
                                this.alert1 = false;
                                this.alert2 = false;
                                this.alert3 = true;
                                this.alert4 = false;
                                this.alert5 = false;
                                this.alert6 = false;
                            }
                        }
                    })
                    .catch((response) => {
                        //console.log(response)
                        let err = Object.assign({}, response)
                        console.log('error', err.response);
                                        
                        if(err.response.status == '488')
                        {
                            this.$router.push('/')
                        }
                    });
                    
                    //console.log('1')
                }
                else
                {
                    this.alert1 = false;
                    this.alert2 = false;
                    this.alert3 = false;
                    this.alert4 = true;
                    this.alert5 = false;
                    this.alert6 = false;
                }
            }
        },
    },

    watch: {
        selectCondition(){
            //console.log(this.selectCondition)
            if(this.selectCondition == '='){
                this.SpecialDisabled = true;
            }
            else
            {
                this.SpecialDisabled = false;
            }
        },
        jobTypeSelect(){
            //console.log(this.jobTypeSelect);
            if(this.jobTypeSelect == 'Разовая задача')
            {
                this.jobRepeatEveryDisabled = true;
                this.jobScheduledDisabled = false;
                this.schoolURLRD = true;
                this.showSaveCopyButtons = false;
                this.showStartButton = true;
                this.uuid = uuidv4();
            }
            else
            {
                this.jobRepeatEveryDisabled = false;
                this.jobScheduledDisabled = true;
            }
        },
        exm00(){
            if(this.exm00 == 1){
                this.exm05=0;
                this.exm10=0;
                this.exm15=0;
                this.exm20=0;
                this.exm25=0;
                this.exm30=0;
                this.exm35=0;
                this.exm40=0;
                this.exm45=0;
                this.exm50=0;
                this.exm55=0;
            }
        },
        exm05(){
            if(this.exm05 == 1){
            this.exm00=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm10(){
            if(this.exm10 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm15(){
            if(this.exm15 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm20(){
            if(this.exm20 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm25(){
            if(this.exm25 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm30(){
            if(this.exm30 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm35(){
            if(this.exm35 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm40(){
            if(this.exm40 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm45(){
            if(this.exm45 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm50(){
            if(this.exm50 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm55=0;
            }
        },
        exm55(){
            if(this.exm55 == 1){
            this.exm00 = 0;
            this.exm05 = 0;
            this.exm10 = 0;
            this.exm15 = 0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            }
        }
        
    },
}
</script>